import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "./useModal";

export const useConfirmModal = async (props) => {
  return new Promise((resolve, reject) => {
    useModal().open({
      name: ModalName.ConfirmModal,
      closeOnOverlay: false,
      props: {
        ...props,
        onConfirm: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      },
    });
  });
};
