<template>
  <div class="collection-topbar-wallet">
    <div class="collection-topbar-wallet-address-wrapper">
      <span class="collection-topbar-wallet-address-provider">{{ walletType }}:</span>
      <span class="collection-topbar-wallet-address">{{ walletAddress }}</span>
      <span
        class="collection-topbar-wallet-disconnect"
        @click.prevent="disconnect"
      >
        <IDisconnect width="12" />
      </span>
    </div>
    <div class="collection-topbar-wallet-balance">
      <span class="collection-topbar-wallet-balance-title">Balance:</span>
      <div>
        <span class="collection-topbar-wallet-balance-meth">
          {{ methBalance }} METH
          <a
            href="#"
            class="collection-topbar-wallet-balance-meth-send"
            title="Send METH"
            @click.prevent="openSendMeth"
          >
            <ISend width="12" />
          </a>
        </span>
        <span class="collection-topbar-wallet-balance-eth">
          {{
            ethBalance
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { shorten } from "../../helpers/utils";
import ISend from "../../assets/icons/send.svg?inline"
import { useWeb3Store } from "@/store/web3";
import { computed, defineComponent, ref } from "vue";
import { useModal } from "@/composables/useModal";
import { ModalName } from "../Modal/modal-name";
import IDisconnect from "@/assets/icons/disconnect.svg?inline"
import { disconnectWallet } from "@/web3"
import { useConfirmModal } from "@/composables/useConfirmModal";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";


export default defineComponent({
  components: { ISend, IDisconnect },

  setup() {
    const web3Store = useWeb3Store()
    const walletType = computed(() => web3Store.walletType)
    const ethBalance = computed(() => {
      const { balance } = useWeb3Store()
      return `${balance.toFixed(6)} ETH`
    })
    const methBalance = computed(() => formatNumberSpaces(web3Store.meth))
    const walletAddress = computed(() => {
      const { walletAddress } = useWeb3Store()
      return shorten(walletAddress, 4);
    })
    const openSendMeth = () => {
      useModal().open({
        name: ModalName.SendMethModal,
        props: {
          onChain: true
        }
      })
    }
    const disconnect = async () => {
      const confirm = await useConfirmModal({
        title: "Are you sure you want to disconnect your wallet?",
        text: "You will be disconnected",
        confirmText: "Disconnect"
      })
      if (!confirm) return
      disconnectWallet()
    }
    return {
      disconnect,
      openSendMeth,
      walletType, ethBalance,
      walletAddress,
      methBalance,
    }
  },

})
</script>
