<template>
  <div class="start-page-news-letter">
    <div class="start-page-news-letter-bg" />
    <h2 class="start-page-news-letter-title">
      {{ title }}
    </h2>
    <div class="start-page-news-letter-subtitle">
      {{ subtitle }}
    </div>
    <div
      id="kajabi-form"
      class="kajabi-form--inline"
    >
      <form
        data-parsley-validate="true"
        data-kjb-disable-on-submit="true"
        action="https://www.bullsandapesproject.com/forms/2147932379/form_submissions"
        accept-charset="UTF-8"
        method="post"
      >
        <input
          name="utf8"
          type="hidden"
          value="✓"
        >
        <input
          type="hidden"
          name="authenticity_token"
          value="ZD5xx5tUGNGKMvBJhpbQGDLMKWhm/c35JerYQTrXJUNItsPZz69q5YQmpJvJQ31ixbHEnXP6ekpCbUI/+SdQXA=="
        >

        <div class="kajabi-form__content start-page-news-letter-form">
          <div class="text-field kajabi-form__form-item">
            <input
              id="form_submission_name"
              class="app-input start-page-news-letter-input"
              type="text"
              name="form_submission[name]"
              value
              required="required"
              placeholder="First Name"
            >
          </div>
          <div class="email-field kajabi-form__form-item">
            <input
              id="form_submission_email"
              class="app-input start-page-news-letter-input"
              required="required"
              placeholder="Email"
              data-parsley-remote="true"
              data-parsley-remote-validator="kjb_email_validator"
              type="email"
              name="form_submission[email]"
            >
          </div>
          <button
            class="kajabi-form__btn start-page-news-letter-button"
            type="submit"
          >
            Give Me All the Alpha!
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "The Apes are here!"
        },
        subtitle: {
            type: String,
            default: "But there are many announcements and releases yet to come. Sign up now for updates!"
        }
    },
    data() {
        return {
            name: '',
            email: ""
        }
    },
}
</script>