<template>
  <!-- eslint-disable -->
  <div class="pre-mint">
    <div class="pre-mint__banner">
      <img
        class="pre-mint__banner-img"
        src="../assets/img/banners/ape-mint-banner.png"
        alt="ape-event"
      >
      <div class="pre-mint__banner-overlay" />
    </div>
    <div class="pre-mint__content">
   
      <div class="pre-mint__info">
        <div class="pre-mint-opensea-container">
          <a
            href="https://opensea.io/collection/bullsandapes-apes"
            target="_blank"
            rel="noopener noreferrer"
            class="pre-mint-opensea"
          >
            <div>
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
                alt="opensea"
              />
            </div>
            <span>BAP Apes</span>
          </a>
        </div>
        <h2 class="pre-mint__info-subtitle">
          {{ titleText }}
        </h2>
        <p class="pre-mint__info-caption">
          {{ captionText }}
        </p>
        <h1>THE APE INVASION HAS BEGUN!!</h1>
        <div v-if="isConnected" class="pre-mint__action-box">
          <div class="pre-mint__action-box-wallet">
            <WalletInfo />
          </div>
          <div
            v-if="canBeController"
            class="collection-page-safe-claim-switch-wrapper"
          >
            <a
              href="#"
              class="collection-page-safe-claim-switch-button collection-page-top-actions-button"
              @click.prevent="switchSafeClaim"
            >
              <ILock width="14" />
              <template v-if="mintToSource">
                Apes goes to
                <span>Source Wallet</span>
              </template>
              <template v-else>
                Apes goes to
                <span>Controller Wallet</span>
              </template>
            </a>
            <div class="collection-page-safe-claim-switch-tooltip">
              Currently Minting to
              <b>{{ !mintToSource ? walletAddress : sourceWallet }}</b>
            </div>
          </div>
        </div>
        <div
          v-if="isConnected && isExchangeAvailable"
          class="pre-mint__action-box"
        >
          <p v-if="!isGettingBasicInfo" class="pre-mint__info-announce">
            You have {{ mintedPortalPasses || 0 }} Portal Pass{{
              mintedPortalPasses === 1 ? "" : "es"
            }}
          </p>
          <p
            v-else
            class="pre-mint__info-announce"
          >
            Getting your Portal Passes...
          </p>

          <div class="pre-mint__info-cta">
            <div class="pre-mint__info-cta-container">
              <div
                :style="`display: ${mintedPortalPasses > 0 ? 'flex' : 'none'}`"
                class="pre-mint__info-cta-btns"
              >
                <div>
                  <button
                    :disabled="amountToExchange <= 1 || exchanging"
                    @click="amountToExchange--"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15"
                      />
                    </svg>
                  </button>
                  <input
                    v-model="amountToExchange"
                    name="mint-amount"
                    type="number"
                    min="1"
                    :max="mintedPortalPasses"
                    placeholder="amount"
                    :disabled="exchanging"
                  >
                  <button
                    :disabled="
                      amountToExchange >= mintedPortalPasses || exchanging
                    "
                    @click="amountToExchange++"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </div>
                <button
                  :disabled="
                    exchanging || amountToExchange >= mintedPortalPasses
                  "
                  class="square"
                  @click="amountToExchange = mintedPortalPasses"
                >
                  MAX
                </button>
              </div>
              <button
                v-if="mintedPortalPasses > 0"
                class="pre-mint__btn"
                :disabled="
                  exchanging ||
                    amountToExchange <= 0 ||
                    amountToExchange > maxPerTx
                "
                @click="exchangePortalPass"
              >
                {{ exchanging ? "Exchanging..." : "Exchange" }}
              </button>
            </div>
          </div>
        </div>
        <button
          v-if="!isConnected"
          class="pre-mint__btn center"
          :disabled="isGettingBasicInfo"
          @click="connectWallet"
        >
          {{ isGettingBasicInfo ? "Connecting..." : "Connect Wallet" }}
        </button>

        <!--<div v-if="!isMintOpen">
          <h3 class="pre-mint__info-subtitle mt-4">Sale Starts in</h3>

          <Countdown
            :flip-animation="false"
            deadline-i-s-o="2023-02-08T01:00:00.000Z"
            :main-color="'#fff'"
            :label-color="'#fff'"
            :label-size="'1.5rem'"
          />

          <div
            style="display: grid; justify-content: center; text-align: center"
          >
            <button
              v-if="!currentUserTiers"
              :disabled="isGettingUserTiers || currentUserTiers?.length > 0"
              class="pre-mint__btn"
              @click="connectWallet"
            >
              Check Your Tiers
            </button>
            <div
              v-if="currentUserTiers && !isGettingUserTiers"
              class="pre-mint__mint-status-tiers"
            >
              <h5>Your Tiers</h5>
              <ul>
                <li
                  v-for="tier in tiers"
                  :key="tier.code"
                  :style="`display: ${
                    currentUserTiers?.includes(tier.code) ? 'block' : 'none'
                  }`"
                  :class="`pre-mint__mint-status-tier eligible`"
                >
                  {{ tier.title }}
                </li>
              </ul>
            </div>
            <p v-if="isGettingUserTiers">
              Loading...
            </p>
          </div>
        </div>-->

        <div
          v-if="isMintOpen"
          class="pre-mint__mint"
        >
          <h3 class="pre-mint__mint-title">
            Minting is Open!
          </h3>

          <!--<div class="pre-mint__mint-status">
            <h4>Current Active Tier</h4>
            <p class="pre-mint__mint-status-tier eligible">
              Tier {{ currentTier + 1 }}
            </p>
            <div
              v-if="isConnected && !isGettingUserTiers"
              class="pre-mint__mint-status-tiers"
            >
              <h5>Your Tiers</h5>
              <ul>
                <li
                  v-for="tier in tiers"
                  :key="tier.code"
                  :style="`display: ${
                    currentUserTiers?.includes(tier.code) ? 'block' : 'none'
                  }`"
                  :class="`pre-mint__mint-status-tier ${
                    tier.code === currentTier ? 'eligible' : ''
                  }`"
                >
                  {{ tier.title }}
                </li>
              </ul>
            </div>
            <p v-if="isGettingUserTiers">Loading...</p>
          </div>-->
          <div v-if="isConnected" class="pre-mint__mint-action">
            <div v-if="isConnected" class="pre-mint__info-cta-container">
              <div class="pre-mint__info-cta-btns">
                <div>
                  <button
                    :disabled="
                      !isUserAllowedToMint ||
                        amountToMint <= 1 ||
                        minting ||
                        soldOut
                    "
                    @click="amountToMint--"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15"
                      />
                    </svg>
                  </button>
                  <input
                    v-model="amountToMint"
                    name="mint-amount"
                    type="number"
                    min="1"
                    :max="maxMintPerTx"
                    placeholder="amount"
                    :disabled="minting || !isUserAllowedToMint || soldOut"
                  >
                  <button
                    :disabled="
                      !isUserAllowedToMint ||
                        amountToMint >= maxMintPerTx ||
                        minting ||
                        soldOut
                    "
                    @click="amountToMint++"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </button>
                </div>
                <button
                  :disabled="
                    !isUserAllowedToMint ||
                      minting ||
                      amountToMint >= maxMintPerTx ||
                      soldOut
                  "
                  class="square"
                  @click="amountToMint = maxMintPerTx"
                >
                  MAX
                </button>
              </div>
              <button
                class="pre-mint__btn"
                :disabled="
                  isButtonMintDisabled || apesStats.totalSupply === null
                "
                @click="mint"
              >
                {{
                  soldOut
                    ? "Sold Out"
                    : minting
                      ? "Minting..."
                      : "Mint" +
                        " " +
                        (amountToMint * mintingPrice).toFixed(2) +
                        " ETH"
                }}
              </button>
            </div>
            <div class="pre-mint__checkboxes">
              <div class="checkbox-wrapper">
                <input
                  class="inp-cbx"
                  v-model="ethBackGuarantee"
                  type="checkbox"
                  id="eth-back-guarantee"
                />
                <label class="cbx" for="eth-back-guarantee"
                  ><span>
                    <svg width="12px" height="10px">
                      <use xlink:href="#eth-check"></use></svg></span
                  ><span
                    >I confirm I have read the
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.bullsandapesproject.com/eth-back-guarantee"
                      >ETH-back guarantee</a
                    >
                  </span>
                </label>
                <svg class="inline-svg">
                  <symbol id="eth-check" viewbox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </symbol>
                </svg>
              </div>
              <div class="checkbox-wrapper">
                <input class="inp-cbx" v-model="TOS" id="tos" type="checkbox" />
                <label class="cbx" for="tos"
                  ><span>
                    <svg width="12px" height="10px">
                      <use xlink:href="#tos-check"></use></svg></span
                  ><span
                    >I confirm I have read the

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      download="BullsAndApes_TermsOfService.pdf"
                      href="https://storage.mint.bullsandapesproject.com/documents/terms_of_use.pdf"
                      >Terms of Service</a
                    >
                  </span>
                </label>
                <svg class="inline-svg">
                  <symbol id="tos-check" viewbox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </symbol>
                </svg>
              </div>
            </div>
            <!--<p
              v-if="!isUserAllowedToMint && isConnected && !isGettingUserTiers"
              class="pre-mint__info-cta-helper"
            >
              You are not eligible to mint in this tier.
            </p>-->
          </div>
        </div>
        <div v-if="!hideCounter" class="pre-mint__mint-counter">
          <div class="pre-mint__info-cta-goals">
            <div :class="`pre-mint__info-cta-goal active`">
              <h4>Mint Counter</h4>
              <p>{{ counter }} / 10000 Mints Remaining</p>
              <div class="pre-mint__info-cta-goal-progress">
                <div
                  class="pre-mint__info-cta-goal-progress-bar"
                  :style="`width: ${(counter / 10000) * 100}%`"
                />
              </div>
            </div>
          </div>
        </div>
        <!--<div class="pre-mint__info-details">
          <p class="pre-mint__info-details-title">
            10,000 Apes in the Full Collection
          </p>
          <p class="pre-mint__info-details-minus">
            <span class="minus">-</span>
            <span>5,000 Apes Sold During Pre-Mint</span>
          </p>
          <p class="pre-mint__info-details-minus">
            <span class="minus">-</span>
            <span>1,500 Apes Set Aside for Treasury and Team</span>
          </p>
          <p class="pre-mint__info-details-result">
            3,500 Apes Remaining to Mint Feb 7th
          </p>
        </div>-->
      </div>

      <!--<h3 class="pre-mint__info-title">Allow List Details</h3>

      <div class="pre-mint__boxes">
        <div
          v-for="tier in tiers"
          :key="tier.name"
          class="pre-mint__box outlined"
        >
          <div class="pre-mint__box-header">
            <h4 class="pre-mint__box-title">
              {{ tier.title }}
            </h4>
            <p class="pre-mint__box-price">
              {{ tier.price.toFixed(2) }} ETH
            </p>
          </div>
          <p class="pre-mint__box-description">
            {{ tier.description }}
          </p>
        </div>
      </div>-->

      <h3 class="pre-mint__info-title">
        Pre-Mint Result
      </h3>

      <div class="pre-mint__state-img">
        <img
          src="../assets/img/mint-stats.png"
          alt="Pre-Mint State"
        >
      </div>

      <div class="pre-mint__shop">
        <h2>Do You Own A Bull Yet ??</h2>
        <p>
          You've seen the value of OG & Teen Bulls skyrocket as we continue to
          deliver
        </p>
        <p>
          What do you think will happen when the word gets out about Apes?!?
        </p>

        <div class="pre-mint-opensea-container">
          <a
            class="pre-mint-opensea"
            target="_blank"
            href="https://opensea.io/collection/bullsandapes-genesis"
          >
            <div>
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
                alt="opensea"
              >
            </div>
            <span>OG Bulls</span>
          </a>
          <a
            class="pre-mint-opensea"
            target="_blank"
            href="https://opensea.io/collection/bullsandapes-teenbulls"
          >
            <img
              src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
              alt="opensea"
            >
            <span>Teen Bulls</span>
          </a>
        </div>
      </div>

      <StartPageNewsLetterVue />

      <div class="pre-mint__faqs">
        <h2 class="pre-mint__faqs-title">
          Frequently Asked Questions
        </h2>
        <div class="pre-mint__faqs-container">
          <h3 class="pre-mint__faqs-subtitle">
            Mint Questions
          </h3>

          <button
            v-for="faq in faqs.mint"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>

          <h3 class="pre-mint__faqs-subtitle">
            Ape Questions
          </h3>

          <button
            v-for="faq in faqs.apes"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>

          <h3 class="pre-mint__faqs-subtitle">
            Dynamic NFT & Traits Questions
          </h3>

          <button
            v-for="faq in faqs.nfts"
            :key="faq.question"
            class="pre-mint__faq blur"
            :aria-expanded="faq.open"
            @click="faq.open = !faq.open"
          >
            <div class="pre-mint__faq-question">
              <span>{{ faq.question }}</span>
            </div>
            <div class="pre-mint__faq-answer">
              <p v-html="faq.answer" />
            </div>
          </button>
        </div>
      </div>

      <div class="pre-mint__footer">
        <!--<button class="pre-mint__btn" @click="handleFooterMintButtonClick">
          Mint Now
        </button>-->
        <div class="pre-mint-opensea-container">
          <a
            href="https://opensea.io/assets/ethereum/0x389dcc122a2304a95ef929e71b7ef2504c6cc3ac/1"
            target="_blank"
            rel="noopener noreferrer"
            class="pre-mint-opensea"
          >
            <div>
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
                alt="opensea"
              >
            </div>
            <span>Portal Pass</span>
          </a>
        </div>
        <div class="pre-mint-opensea-container">
          <a
            href="https://opensea.io/collection/bullsandapes-apes"
            target="_blank"
            rel="noopener noreferrer"
            class="pre-mint-opensea"
          >
            <div>
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.svg"
                alt="opensea"
              >
            </div>
            <span>BAP Apes</span>
          </a>
        </div>
      </div>
      <div class="pre-mint__content-overlay" />
    </div>
  </div>
</template>

<script>
import StartPageNewsLetterVue from "../components/StartPage/StartPageNewsLetter.vue";
import PAGE_DATA from "../config/pre_mint_data.json";
import { Countdown } from "vue3-flip-countdown";
import { useStorageApi } from "@/api/useStorageApi";
import { captureError } from "@/helpers/captureError";
import { connectWallet } from "@/web3";
import { useWeb3Store } from "@/store/web3";
import { useBapApesContract, usePortalPassContract } from "@/web3/contracts";
import { useToastTransaction } from "@/composables/useToast/useToastTransaction";
import WalletInfo from "@/components/Collection/WalletInfo.vue";

import Web3 from "web3";
import { useConfirmModal } from "@/composables/useConfirmModal";
import { useToast } from "@/composables/useToast";

const { faqs, page_data, tiers } = PAGE_DATA;

const mint = faqs.mint.map((faq) => ({
  ...faq,
  open: false,
  active: false,
}));

const apes = faqs.apes.map((faq) => ({
  ...faq,
  open: false,
}));

const nfts = faqs.nfts.map((faq) => ({
  ...faq,
  open: false,
}));

let mintToSource = false;
let currentTier = null;
let priceTier = null;
let currentUserTiers = null;
let mintedPortalPasses = null;
let isInActiveTier = false;
let amountToExchange = 1;
let exchanging = false;
let exchangingError = false;
let maxPerTx = 50;
let isGettingBasicInfo = false;
let currentUserTier = null;
let isUserAllowedToMint = false;
let amountToMint = 1;
let maxMintPerTx = 50;
let minting = false;
let mintingError = false;
let exchangeActive = false;
let isGettingUserTiers = false;
let ethBackGuarantee = false;
let TOS = false;

let apesStats = {
  totalSupply: null, // total amount of Apes minted
  treasurySupply: null, // remaining of Apes locked for treasury, starts at 1500 and goes down as treasury mints them
  reservedSupply: null, // remaining of Apes locked for portal passes exchange, starts at 5000 and goes down as portals gets exchanged
  publicSupply: null, // remaining of Apes available for public mint, starts at 3500 and goes down as public mints them
};

export default {
  name: "PreMint",
  mounted() {
    this.getCurrentTier();
    this.getApesStats();
    if (this.walletAddress) {
      this.getBasicInfo();
    }
  },
  data() {
    return {
      mintToSource,
      currentTier,
      priceTier,
      currentUserTiers,
      mintedPortalPasses,
      isInActiveTier,
      amountToExchange,
      exchanging,
      exchangingError,
      maxPerTx,
      isGettingBasicInfo,
      isUserAllowedToMint,
      currentUserTier,
      amountToMint,
      maxMintPerTx,
      minting,
      mintingError,
      apesStats,
      exchangeActive,
      isGettingUserTiers,
      ethBackGuarantee,
      TOS,
      faqs: {
        mint,
        apes,
        nfts,
      },
      pre: page_data.pre,
      fast: page_data.fast,
      main: page_data.main,
      tiers,
    };
  },
  props: {
    handleBuyTickets: {
      type: Function,
    },
  },
  components: {
    StartPageNewsLetterVue,
    Countdown,
    WalletInfo,
  },
  watch: {
    async walletAddress() {
      this.isGettingBasicInfo = true;
      await this.getBasicInfo();
      this.isGettingBasicInfo = false;
    },
  },

  methods: {
    async connectWallet() {
      await connectWallet();
    },
    async switchSafeClaim() {
      this.mintToSource = !this.mintToSource;
      this.isGettingBasicInfo = true;
      await this.getBasicInfo();
      this.isGettingBasicInfo = false;
    },
    async getBasicInfo() {
      if (!this.isConnected) {
        connectWallet();
        return;
      }

      try {
        await this.checkTier();
      } catch (error) {
        captureError(error);
      }

      try {
        await this.getMintedPortalPasses();
      } catch (error) {
        captureError(error);
      }
    },
    async getCurrentTier() {
      try {
        const data = await useStorageApi({
          throw: true,
        }).exec({
          url: "/currenttier",
        });
        if (typeof data.tier === "number") {
          this.currentTier = data.tier;
          this.priceTier = data.price;
          // for testing
          // this.currentTier = 9;
        }
        let isExchangeActive = false;
        if (typeof data.exchangeActive === "string") {
          isExchangeActive = data.exchangeActive === "true";
        }
        if (typeof data.exchangeActive === "boolean") {
          isExchangeActive = data.exchangeActive;
        }

        this.exchangeActive = isExchangeActive;

        // for testing
        // const active = "false";
        // this.exchangeActive = active === "true";
      } catch (error) {
        captureError(error);
      }
    },
    async checkTier() {
      this.isGettingUserTiers = true;
      try {
        // const walletAddress = useWeb3Store().walletAddress;
        const walletAddress = this.mintToSource
          ? useWeb3Store().safeClaim.sourceWallet
          : useWeb3Store().walletAddress;
        const data = await useStorageApi({ throw: true }).exec({
          url: `/wallet-tier`,
          params: {
            wallet: walletAddress,
          },
        });
        if (data) {
          this.currentUserTiers = [...data];
          // for testing
          // this.currentUserTiers = [0, 1, 2];
          this.currentUserTier = [...data][0] + 1;
          // for testing
          //   this.currentUserTier = 1;
          this.isUserAllowedToMint = this.currentUserTiers?.includes(
            this.currentTier
          );
        }
      } catch (error) {
        captureError(error);
      } finally {
        this.isGettingUserTiers = false;
      }
    },
    async getMintedPortalPasses() {
      try {
        const walletAddress = useWeb3Store().walletAddress;
        const contract = usePortalPassContract();
        const data = await contract.methods.balanceOf(walletAddress, 1).call();
        if (data) {
          this.mintedPortalPasses = data;
          // for testing
          //   this.mintedPortalPasses = 0;
        }
      } catch (error) {
        captureError(error);
      }
    },
    async mint() {
      try {
        this.minting = true;
        const contract = useBapApesContract(true);
        const walletAddress = this.mintToSource
          ? useWeb3Store().safeClaim.sourceWallet
          : useWeb3Store().walletAddress;
        const senderWallet = useWeb3Store().walletAddress;
        const amount = this.tiers[this.priceTier].price * this.amountToMint;
        const { signature, tierPrice } = await this.getMintSignature(
          walletAddress,
          this.amountToMint
        );

        if (!signature) {
          console.error("no signature");
          return;
        }

        if (tierPrice !== this.priceTier) {
          const toast = useToast();
          toast.error({
            title: "Tier price has change, please refresh the page",
          });
        }

        if ([...this.currentUserTiers]?.includes(this.currentTier)) {
          await useToastTransaction(
            contract.methods.purchase(
              walletAddress,
              this.amountToMint,
              tierPrice,
              signature
            ),
            {
              text: "Purchase in progress",
              successText: `Congrats! You have purchased ${
                this.amountToMint
              } Ape${this.amountToMint > 1 ? "s" : ""}!`,
              errorText: "Purchase failed",
              sendOptions: {
                from: senderWallet,
                value: Web3.utils.toWei(amount.toString(), "ether"),
              },
            }
          );
        } else {
          console.error("not in tier");
        }
      } catch (error) {
        const toast = useToast();
        toast.error({
          title: error.response?.data ?? "something went wrong",
        });
      } finally {
        this.minting = false;
      }
    },
    async exchangePortalPass() {
      this.exchanging = true;
      try {
        const contract = useBapApesContract(true);
        const portalPassContract = usePortalPassContract(true);
        const walletAddress = this.mintToSource
          ? useWeb3Store().safeClaim.sourceWallet
          : useWeb3Store().walletAddress;
        const senderWallet = useWeb3Store().walletAddress;

        const confirm = await useConfirmModal({
          title:
            "You will send a transaction to approve the Portal Pass Exchange",
          confirmText: "Proceed",
        });
        if (!confirm) return;

        const isApproved = await portalPassContract.methods
          .isApprovedForAll(senderWallet, contract._address)
          .call();

        if (!isApproved) {
          await useToastTransaction(
            portalPassContract.methods.setApprovalForAll(
              contract.options.address,
              true
            ),
            {
              sendOptions: {
                from: senderWallet,
              },
              text: "Approving portal pass exchange",
              successText: "Portal Pass Exchange approved",
            }
          );
        }

        await useToastTransaction(
          contract.methods.exchangePass(walletAddress, this.amountToExchange),
          {
            sendOptions: {
              from: senderWallet,
            },
            text: "Exchanging in progress",
            successText: `Congrats! You have exchanged ${
              this.amountToExchange
            } Portal Pass${this.amountToExchange > 1 ? "es" : ""} for an Ape!`,
          }
        );

        await this.getMintedPortalPasses();
      } catch (error) {
        captureError(error);
      } finally {
        this.exchanging = false;
      }
    },
    async getMintSignature(walletAddress, mintAmount) {
      if (!this.isConnected) {
        await this.connectWallet();
      }

      const data = await useStorageApi({
        throw: true,
      }).exec({
        url: `/signPurchase`,
        params: {
          to: walletAddress,
          amount: mintAmount,
        },
      });
      return data ?? null;
    },

    async getApesStats() {
      try {
        const contract = useBapApesContract();
        const totalSupply = await contract.methods.totalSupply().call();
        const treasurySupply = await contract.methods.treasurySupply().call();
        const reservedSupply = await contract.methods.reservedSupply().call();
        const publicSupply = await contract.methods.publicSupply().call();
        this.apesStats = {
          totalSupply,
          treasurySupply,
          reservedSupply,
          publicSupply,
        };
      } catch (error) {
        captureError(error);
      }
    },

    handleFooterMintButtonClick() {
      window.scrollTo({
        top: 350,
        behavior: "smooth",
      });
    },
  },

  computed: {
    isConnected: function () {
      return useWeb3Store().isConnected;
    },
    walletAddress: function () {
      return useWeb3Store().walletAddress;
    },
    counter: function () {
      if (this.currentTier === 0) {
        return 10000 - 5000 - (3500 - this.apesStats.publicSupply);
      }
      if (this.currentTier > 0) {
        return 10000 - 6500 - (3500 - this.apesStats.publicSupply);
      }
      return 10000;
    },
    sourceWallet: function () {
      return useWeb3Store().safeClaim.sourceWallet;
    },
    canBeController() {
      return useWeb3Store().safeClaim.confirmed;
    },
    isExchangeAvailable: function () {
      // for testing
      //   return false;
      return this.exchangeActive;
    },
    isMintOpen: function () {
      return false;
      // for testing
      // return false;
      return this.currentTier <= this.tiers[this.tiers.length - 1]?.code;
    },
    mintingPrice: function () {
      return 0.22;
      // return this.tiers[this.priceTier]?.price;
    },
    soldOut: function () {
      return true;
      return this.apesStats.publicSupply === 0;
    },
    hideCounter: function () {
      return true;
      if (this.currentTier === 7) {
        return false;
      }
      return this.currentTier > 5;
    },
    titleText: function () {
      return "Sold Out!";
      if (this.currentTier <= 5) {
        return "Minting Now";
      }
      if (this.currentTier === 7) {
        return "Minting On Friday";
      }
      if (this.currentTier > 5) {
        return "Sold Out!";
      }
      return "";
    },
    captionText: function () {
      return "";
      if (this.currentTier === 7) {
        return "February 10th at 2pm CST";
      } else {
        return "";
      }
    },
    isButtonMintDisabled: function () {
      return (
        this.soldOut ||
        !this.isMintOpen ||
        !this.isConnected ||
        this.mintingPrice > this.balance ||
        !this.ethBackGuarantee ||
        !this.TOS
      );
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

.page-wrapper {
  padding-top: 0 !important;
}

body {
  background: #0f0612;
}

.BAP-header {
  display: none;
}

.header-announcement {
  display: none !important;
}

.collection-page-safe-claim-switch-wrapper {
  display: inherit;
  margin: 10px auto;
}

.collection-page-top-actions-button {
  justify-content: center;
}

@media screen and (min-width: 1150px) {
  .BAP-header {
    background: transparent !important;
    justify-content: center !important;
  }

  .header-announcement {
    display: none;
  }

  .BAP-header__logo {
    display: none;
  }

  .BAP-header__socials {
    display: none;
  }

  .BAP-header__nav {
    padding: 0.5rem;
    background: hsla(291, 48%, 11%, 0.3);
    border-radius: 0.5rem;
    /* background filter blur */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
}

.start-page-news-letter {
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  background: #28323e71;
  width: min(calc(100% - 8rem), 500px);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.start-page-news-letter-subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}

.start-page-news-letter input {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #fff;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.start-page-news-letter button {
  background: #ab1efe;
  border: 1px solid #ab1efe;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.start-page-news-letter-bg {
  /* background: none; */
  background: url("../assets/img/Bulls/ApeMale12_Main.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: color;
}

@media screen and (max-width: 768px) {
  .start-page-news-letter {
    width: calc(100% - 4rem);
    height: auto;
  }
}

.footer {
  margin: 0;
}

.flip-clock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .flip-clock {
    gap: 0.75rem;
  }
}
</style>
